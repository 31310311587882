import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout.tsx"
import FeedbackCard from "../../components/feedback_card.tsx"
import ArticlesList from "../../components/articles_list.tsx"
import AboutUs from "../../components/about_us.tsx"

import mockupGif from "../../media/Figma-Mock-up-Datapult.gif"

const DatapultProject = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title="Datapult for iOS">
      <div className="datapult-project-hero" />
      <div className="lg:w-1/2 mx-4 lg:mx-16 lg:ml-12 -mt-24">
        <div className="absolute top-40 right-40 hidden xl:block">
          <FeedbackCard location="projects/datapult" />
        </div>
        <h1>Datapult</h1>
        <p className="lead">
          A Web3 social media utility app on iOS where you can upload, manage,
          and share all your media on IPFS directly from your phone.
        </p>
      </div>
      <div className="lg:w-1/2 mx-4 lg:mx-16 lg:mx-auto mt-16 text-black">
        <h3 className="my-4">Foundations</h3>
        <p>
          A large and growing percentage of social media content (images,
          videos, etc) is made directly on mobile devices. I'd venture to say
          almost all of it is made on mobile devices and when I find that report
          I will link it here). Of the people creating that media, some smaller
          percentage them care about their media being hosted on the
          decentralized web (Web3). Our belief is that a percentage of those
          people care enough to use an app that lets them upload their media to
          IPFS and back it with Filecoin storage. We hope that these people
          might also be willing to pay for this app and service in some way. So
          put it another way, the initial target audience for users of Datapult
          are people on &quot;crypto Twitter&quot; (which TIL; is just regular
          Twitter but like they just talk about crypto currency all the time).
          These decentralized-maximal-futurists would be willing to use Datapult
          when they post because to them, using decentralized storage is
          important.
        </p>
        <h3 className="my-4">Design details</h3>
        <p>
          Datapult will be an iOS app that lets people share files. It will only
          be available on iOS initially. To make it integrate as easily as
          possible with social media apps it will use{" "}
          <a href="https://developer.apple.com/design/human-interface-guidelines/ios/extensions/sharing-and-actions/">
            share extensions
          </a>{" "}
          that are part iOS. With this iOS feature in the app users can
          &quot;datapult&quot; their files from any app that supports sharing.
          When someone shares through Datapult, the app creates a unique link
          that the user can paste into a social media app/site. The image they
          shared to Datapult is stored on IPFS using{" "}
          <a href="https://Web3.storage">Web.Storage</a> and backed to Filecoin.
          The image is processed by Datapult backend services and a{" "}
          <a href="https://sproutsocial.com/insights/twitter-cards/">
            social card
          </a>{" "}
          is created. The app will also alow people to manage the files they
          have uploaded. They will be able to add, update, delete or extend
          storage contracts directly from the app. Any service fees can be paid
          directly in Ethereum by
          <a href="https://docs.metamask.io/guide/mobile-best-practices.html#deeplinking">
            {" "}
            deep linking payment requests
          </a>{" "}
          directly to the Metamask iOS app. And since{" "}
          <a href="https://techcrunch.com/2021/09/10/apple-prohibited-from-blocking-outside-payment-in-epic-ruling/">
            Apple has recently been forced by the courts
          </a>{" "}
          to allow linking to &quot;alternative payment&quot; we believe that
          this is a new opportunity for the use of crypto currency for payment
          of digital services inside iOS apps.
        </p>
        <h3 className="my-4">Sketched mockup</h3>
        <img
          src={mockupGif}
          alt="Datapult mockup UI walk-through"
          className="md:float-right p-6 w-80 iphone"
        />
        <p>
          In the animated walk-through shown here we are showing the larger
          context for the usage of Datapult. We feel that there is a need by
          people to use Web3 for more of their day-to-day storage needs. One
          very common day-to-day task on a mobile device is sharing a photo. We
          hope that we can convince people to use Datapult to share photos when
          they post them publicly on social media. The design challenge is the
          understanding that we are asking our users to perform an additional
          step when sharing an image online. We want to make that experience
          feel as snappy as possible.
        </p>
        <h3 className="my-4">Private Alpha</h3>
        <p>
          Datapult has a release window of late 2021 and we need help! Please
          use the feedback form on this page to request early access to the
          alpha version to get on the wait-list. The alpha will be on iOS only
          and provide up to 1TB of free storage on Filecoin backed IPFS.
        </p>
        <h3 className="my-4">Thoughts? Feedback?</h3>
        <p>
          We are always interested in hearing thoughts and feedback from the
          community. If you have anything you&#39;d like to share please use the
          feedback form on this page. Someone from TeamZero will get back to you
          as soon as we can.
        </p>
      </div>
      <div className="lg:w-1/2 m-1 lg:mx-auto">
        <h2 className="m-2">Recent Updates</h2>
        <ArticlesList posts={posts} />
      </div>

      <div
        id="form"
        className="mx-4 lg:mr-20 grid grid-cols-1 lg:grid-cols-2 gap-4"
      >
        <div className="m-auto order-last mt-6 lg:order-first lg:mr-10 pb-4">
          <FeedbackCard location="projects/datapult" />
        </div>
        <div className="mt-4 ml-1 mr-10">
          <AboutUs />
        </div>
      </div>
    </Layout>
  )
}

export default DatapultProject

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { project: { eq: "Datapult" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          author
          tags
          project
        }
      }
    }
  }
`
